<template>
  <div>
    <!-- <Setup v-if="getCategories.length === 0 && !categoriesAreLoading" /> -->
    <HomeApp />
  </div>
</template>

<script>
import HomeApp from "../components/HomeV2/index.vue";
// import Setup from "../components/Setup/index.vue";
export default {
  components: {
    HomeApp,
    //Setup,
  },
  computed: {
    getCategories() {
      return this.$store.state.Category.categories;
    },
    categoriesAreLoading() {
      return this.$store.state.Category.categoriesAreLoading;
    },
  },
  name: "Home",
};
</script>
