/* eslint-disable */
const key = "subscription-management-key";
import CJS from "crypto-js";

export function encrypt(originalText) {
  // Encrypt
  let ciphertext = CJS.AES.encrypt(originalText, key).toString();
  ciphertext = ciphertext
    .toString()
    .replace(/\+/g, "xMl3Jk")
    .replace(/\//g, "Por21Ld")
    .replace(/\=/g, "Ml32");
  return ciphertext; // 'my message'
}
export function hmacCrypto(originalText) {
  // Encrypt
  let ciphertext = CJS.HmacSHA256(originalText, key).toString();
  return ciphertext; // 'my message'
}
export function decrypto(ciphertext) {
  const cText = ciphertext
    .toString()
    .replace(/xMl3Jk/g, "+")
    .replace(/Por21Ld/g, "/")
    .replace(/Ml32/g, "=");
  const bytes = CJS.AES.decrypt(cText, key);
  let originalText = bytes.toString(CJS.enc.Utf8);
  return originalText;
}
