<template>
  <div>
    <List @response:succeed="LIST_IS_RECEIVED" ref="packages" />
  </div>
</template>

<script>
import List from "../../../Packages/List.vue";
export default {
  components: {
    List,
  },
  computed: {
    getActivePlan() {
      return this.$store.getters["getActivePlan"];
    },
    getPackageIdFromWebsite() {
      return this.$store.getters["getPackageIdFromWebsite"];
    },
  },
  methods: {
    LIST_IS_RECEIVED() {
      if (this.getActivePlan.status) {
        return this.$emit("next:step", 3);
      }
      if (this.getPackageIdFromWebsite !== "")
        this.$refs.packages.GET_SELECTED_PACKAGE(this.getPackageIdFromWebsite);
      else this.$refs.packages.GET_FREE_PACKAGE();
    },
  },
};
</script>

<style></style>
