export default {
  methods: {
    SET_LOADING_MODAL(loading) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading,
        alertBox: {
          status: false,
          variant: "",
          message: "",
        },
      });
    },
    SET_ALERT_BOX(variant, message) {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: true,
          variant,
          message,
        },
      });
    },
  },
};
