var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"plans__container"},[_c('div',{staticClass:"plans"},[_c('div',{staticClass:"plansHero"},[_c('img',{staticClass:"rounded",attrs:{"src":"/android-chrome-192x192.png","width":"100","alt":""}}),_c('h1',{staticClass:"plansHero__title mt-4"},[_vm._v(_vm._s(_vm.$t("package.title")))]),_c('p',{staticClass:"plansHero__subtitle"},[_vm._v(_vm._s(_vm.$t("package.description")))])]),_c('div',{staticClass:"text-center mb-4"},[_c('button',{staticClass:"btn btn-pill",class:{ donate: _vm.activeComputed === 'monthly' },on:{"click":function($event){_vm.activeComputed = 'monthly'}}},[_vm._v(" "+_vm._s(_vm.$t("monthly"))+" ")]),_c('button',{staticClass:"btn btn-pill",class:{ donate: _vm.activeComputed === 'yearly' },on:{"click":function($event){_vm.activeComputed = 'yearly'}}},[_vm._v(" "+_vm._s(_vm.$t("yearly"))+" ")])]),(_vm.activeComputed === 'monthly')?_c('div',{staticClass:"planItem__container row"},_vm._l((_vm.monthlyPlans),function(item,i){return _c('PackageCard',{key:i,class:{
          'planItem--free': item.id !== _vm.activePackageId,
          'planItem--entp': item.id === _vm.activePackageId,
        },attrs:{"item":item,"type":_vm.$t('monthly'),"active":item.id === _vm.activePackageId}})}),1):_c('div',{staticClass:"planItem__container row"},_vm._l((_vm.yearlyPlans),function(item,i){return _c('PackageCard',{key:i,class:{
          'planItem--free': item.id !== _vm.activePackageId,
          'planItem--entp': item.id === _vm.activePackageId,
        },attrs:{"item":item,"type":_vm.$t('yearly'),"active":item.id === _vm.activePackageId}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }