<template>
  <div class="mt-2">
    <div v-if="!editHiddenInfo" class="text-right">
      <button @click="EDIT_HIDDEN_INFO" class="btn-xs btn btn-pill donate">
        <i class="icon-pencil"></i> {{ $t("editJoinLink") }}
      </button>
    </div>
    <div v-if="editHiddenInfo">
      <VueEditor v-model="editHiddenInfoItem" />
      <div class="mt-3 text-right">
        <button
          @click="UPDATE_HIDDEN_INFO"
          :disabled="updatingHiddenInfo"
          class="btn btn-pill donate"
        >
          {{ updatingHiddenInfo ? "..." : $t("update") }}
        </button>
      </div>
    </div>
    <div v-if="item.hiddenInfo && !editHiddenInfo">
      <div v-html="item.hiddenInfo" class="bg-light card"></div>
    </div>
    <div class="row flex-column-reverse">
      <div
        :class="{
          'col-12 col-lg-12': !noImage,
          'col-12 col-lg-12': noImage,
        }"
      >
        <h3 class="mt-4">
          <span>{{ formatter(item.date) }}</span>
        </h3>
        <h4>{{ item.title }}</h4>
        <p>
          <small
            >{{ item.category.name }} - <i class="icofont-user"></i>
            {{ item.userInfo.fullname }}
          </small>
        </p>
        <div class="my-2">
          <a :href="ADD_TO_GOOGLE_CALENDAR()">
            <button class="btn btn-pill subscription m-2">
              + {{ $t("addToGoogleCalendar") }}
            </button>
          </a>
          <router-link
            :to="`/event-detail/${item.id}/event-date/${item.eventItemId}`"
          >
            <button class="btn btn-pill m-2 donate">
              {{ $t("goToDetail") }}
            </button>
          </router-link>
        </div>
        <div class="line-2">
          {{ item.description }}
        </div>
      </div>
      <div v-if="!noImage" class="col-12 col-lg-12">
        <img
          @error="noImage = true"
          :src="$api.image(item.imagePath)"
          width="100%"
          alt=""
        />
      </div>
    </div>
    <div class="mt-3">
      <div>
        <div v-html="item.overview"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Currency } from "gurhan/helpers/Currency";
import { formatter } from "gurhan/helpers/Date";
import { VueEditor } from "vue2-editor";
import AlertBox from "../../mixins/AlertBox";
export default {
  mixins: [AlertBox],
  components: {
    VueEditor,
  },
  props: {
    item: {
      default: () => {},
    },
  },
  data() {
    return {
      noImage: false,
      showModal: false,
      editHiddenInfo: false,
      editHiddenInfoItem: "",
      updatingHiddenInfo: false,
    };
  },
  computed: {
    ...mapGetters(["getSettings", "getActiveUser"]),
  },
  methods: {
    formatter,
    async UPDATE_HIDDEN_INFO() {
      this.SET_LOADING_MODAL(true);
      const response = await this.$api.put(
        `EventItems/${this.item.eventItemId}`,
        { ...this.item, hiddenInfo: this.editHiddenInfoItem }
      );
      if (response.message === "OK") {
        this.item.hiddenInfo = this.editHiddenInfoItem;
        this.editHiddenInfo = false;
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
      } else {
        this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
      }
    },
    EDIT_HIDDEN_INFO() {
      this.editHiddenInfoItem = this.item.hiddenInfo;
      this.editHiddenInfo = true;
    },
    SET_END_DATE() {
      const date = new Date(this.item.date + "+03:00");
      console.log(date);
      const endDate = date.setMinutes(this.item.duration);
      console.log(endDate);
      const r = new Date(endDate);
      const rmo = r.getMonth() + 1;
      const rd = r.getDate();
      const rh = r.getHours();
      const rm = r.getMinutes();
      return `${r.getFullYear()}${rmo < 10 ? "0" + rmo : rmo}${
        rd < 10 ? "0" + rd : rd
      }T${rh < 10 ? "0" + rh : rh}${rm < 10 ? "0" + rm : rm}`;
    },
    SET_START_DATE() {
      const r = new Date(this.item.date + "+03:00");
      const rmo = r.getMonth() + 1;
      const rd = r.getDate();
      const rh = r.getHours();
      const rm = r.getMinutes();
      return `${r.getFullYear()}${rmo < 10 ? "0" + rmo : rmo}${
        rd < 10 ? "0" + rd : rd
      }T${rh < 10 ? "0" + rh : rh}${rm < 10 ? "0" + rm : rm}`;
    },
    ADD_TO_I_CALENDAR() {
      return `webcal://${
        this.getSettings.website
      }/event/${this.item.title.replace(/ /g, "-")}/2024-01-12/?ical=1`;
      // const url = `${baseurl}&dates=${this.SET_START_DATE()}/${this.SET_END_DATE()}&text=${
      //   this.item.title
      // }+${this.getSettings.title}&sprop=website:${this.getSettings.website}`;
      // return url;
    },
    ADD_TO_GOOGLE_CALENDAR() {
      const baseurl = "https://www.google.com/calendar/event?action=TEMPLATE";
      const url = `${baseurl}&dates=${this.SET_START_DATE()}/${this.SET_END_DATE()}&text=${
        this.item.title
      }+${this.getSettings.title}&sprop=website:${this.getSettings.website}`;
      return url;
    },
    Currency(p) {
      return Currency(p, "TRY");
    },
  },
};
</script>

<style></style>
