<template>
  <div class="planItem col-12 col-md-6 col-lg-6 col-xl-3">
    <div class="card min-height-250">
      <div class="card__header">
        <div class="card__icon symbol symbol--rounded"></div>
        <h2 class="pricing-title">{{ item.package.name }}</h2>
      </div>
      <div class="card__desc">
        {{ item.package.description }}
      </div>
      <div class="mt-4">
        <button
          :disabled="active"
          @click="GO_TO_SUBSCRIPTON(item)"
          class="button position-sticky"
        >
          {{ active ? $t("alreadyPackage") : $t("getStarted") }}
        </button>
      </div>
    </div>

    <div class="price">
      {{ Currency({ price: item.totalCost }, "USD") }}
      <span
        >/ {{ 30 > item.period ? $t("day", { d: item.period }) : type }}</span
      >
    </div>

    <ul class="featureList">
      <li
        :title="module.description"
        v-for="(module, i) in item.package.modules"
        :key="i"
      >
        {{ module.name }}
      </li>
    </ul>

    <button @click="GO_TO_SUBSCRIPTON(item)" :disabled="active" class="button">
      {{ active ? $t("alreadyPackage") : $t("getStarted") }}
    </button>
  </div>
</template>

<script>
import { Currency } from "gurhan/helpers/Currency";
import { encrypt } from "../../helpers/subscription.helper";

export default {
  props: {
    item: {
      default: () => ({}),
    },
    type: {
      default: "",
    },
    active: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    Currency,
    GO_TO_SUBSCRIPTON(item, callback) {
      const data = {
        id: item.id,
        amount: item.totalCost,
        currency: "USD",
      };
      const hash = encrypt(JSON.stringify(data));
      this.$router.push(
        `/subscription?hash=${hash}${callback ? `&callback=${callback}` : ""}`
      );
    },
  },
};
</script>

<style></style>
