<template>
  <div class="row align-items-center" v-if="type === 'student'">
    <div class="col-12">
      <div class="line-1">{{ item.courseTitle }}</div>
    </div>
    <div class="col-6">
      <small class="badge badge-secondary"
        >{{ $t("totalPrice") }}:
        {{ Currency({ price: item.totalPrice }) }}</small
      >
    </div>
    <div class="col-6 text-right">
      <span class="text-primary">
        <i class="icon-user"></i> {{ item.studentCount }}</span
      >
    </div>
    <div class="col-12"><hr /></div>
  </div>
  <div class="row align-items-center" v-else>
    <div class="col-12">
      <div class="line-1">{{ item.courseTitle }}</div>
    </div>
    <div class="col-6">
      <small class="badge badge-secondary"
        >{{ $t("studentCount") }}: {{ item.studentCount }}</small
      >
    </div>
    <div class="col-6 text-right">
      <span class="text-primary">
        <i class="icofont-coins"></i>
        {{ Currency({ price: item.totalPrice }) }}</span
      >
    </div>
    <div class="col-12"><hr /></div>
  </div>
</template>

<script>
import { Currency } from "gurhan/helpers/Currency";
export default {
  props: {
    item: {
      default: () => ({ title: "" }),
    },
    type: {
      default: "student",
    },
  },
  methods: {
    Currency,
  },
};
</script>

<style></style>
