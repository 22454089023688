<template>
  <apexchart
    v-if="loaded"
    type="bar"
    height="350"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>

<script>
export default {
  props: {
    series: {
      default: () => [],
    },
    titles: {
      default: () => [],
    },
    title: {
      default: "",
    },
  },
  data() {
    return {
      loaded: false,
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0,
        },
        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
            style: {
              fontSize: 8,
            },
          },
          categories: this.titles,
          tickPlacement: "on",
        },
        yaxis: {
          title: {
            text: this.title,
          },
        },
        fill: {
          type: "gradient",
          colors: ["#f29c90"],
          gradient: {
            shade: "light",
            gradientToColors: ["#ff5740", "#000"],
            type: "horizontal",
            shadeIntensity: 0.25,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 2000);
  },
};
</script>

<style></style>
