<template>
  <Modal
    v-model="addCategory"
    @ok="SEND_TO_ADD_SECTION"
    ref="addCategoryModal"
    :title="$t('createCategory')"
  >
    <section class="vid-title-sec">
      <div class="container">
        <div
          class="alert"
          :class="`alert-${alertBox.variant}`"
          v-if="alertBox.status"
        >
          {{ alertBox.message }}
        </div>
        <div v-if="getCategories.length > 0">
          <h5 class="text-primary">{{ $t("createCategorySetup") }}</h5>
          <p class="mb-4 mt-2">
            {{ $t("createCategorySetupDescription") }}
          </p>
        </div>
        <div v-else class="mb-4">
          <h5 class="text-primary">
            {{ $t("setupDescription1", { fullname: getActiveUser.fullname }) }}
          </h5>
          <p class="mt-4">
            {{ $t("setupDescription2", { fullname: getActiveUser.fullname }) }}
          </p>
          <p class="mt-4">{{ $t("setupDescription3") }}</p>
        </div>
        <form @submit.prevent="SET_SUBMIT_SECTION">
          <div class="vid-title">
            <h2 class="title-hd">{{ $t("categoryName") }}</h2>
            <div class="form_field">
              <input
                type="text"
                maxlength="100"
                v-model="form.name"
                :placeholder="$t('addHereMax100Char')"
              />
            </div>
          </div>
        </form>
      </div></section
  ></Modal>
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
export default {
  components: { Modal },
  data() {
    return {
      addCategory: false,
      form: { id: -1, name: "" },
      alertBox: { status: false, message: "", variant: "success" },
    };
  },
  props: {
    product: {
      default: () => {},
    },
    productIndex: {
      default: -1,
    },
    noRedirect: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    getCategories() {
      return this.$store.state.Category.categories;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    addCategory(data) {
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
  },
  methods: {
    SET_SUBMIT_SECTION() {
      this.SEND_TO_ADD_SECTION(this.$refs.addCategoryModal.SET_IS_LOADING);
    },
    async SEND_TO_ADD_SECTION(loading) {
      loading(true);
      const fd = new FormData();
      fd.append("Name", this.form.name);
      fd.append("Description", "-");
      fd.append("Image", "");

      this.alertBox = await this.$store.dispatch("SEND_TO_ADD_CATEGORY", {
        fd,
        form: {
          ...this.form,
          description: "-",
          productCount: 0,
          imagePath: "/Images/default.png",
        },
      });
      loading(false);
      setTimeout(() => {
        if (this.alertBox.variant === "success") {
          this.$store.commit("SET_CATEGORY_ARE_LOADING", true);
          this.addCategory = false;
          setTimeout(() => {
            if (!this.noRedirect)
              this.$router.push(`/category/${this.alertBox.id}`);
            else this.addCategory = false;
          }, 100);
        }
        setTimeout(() => {
          this.$store.commit("SET_CATEGORY_ARE_LOADING", false);
        }, 1000);
      }, 2500);
    },
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal === "add-category") {
          this.alertBox.status = false;
          this.form.name = "";
          this.addCategory = true;
        } else this.addCategory = false;
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
  },
};
</script>
