<template>
  <section class="plans__container">
    <div class="plans">
      <div class="plansHero">
        <img
          src="/android-chrome-192x192.png"
          width="100"
          class="rounded"
          alt=""
        />
        <h1 class="plansHero__title mt-4">{{ $t("package.title") }}</h1>
        <p class="plansHero__subtitle">{{ $t("package.description") }}</p>
      </div>
      <div class="text-center mb-4">
        <button
          class="btn btn-pill"
          @click="activeComputed = 'monthly'"
          :class="{ donate: activeComputed === 'monthly' }"
        >
          {{ $t("monthly") }}
        </button>
        <button
          @click="activeComputed = 'yearly'"
          class="btn btn-pill"
          :class="{ donate: activeComputed === 'yearly' }"
        >
          {{ $t("yearly") }}
        </button>
      </div>
      <div v-if="activeComputed === 'monthly'" class="planItem__container row">
        <PackageCard
          v-for="(item, i) in monthlyPlans"
          :item="item"
          :type="$t('monthly')"
          :key="i"
          :active="item.id === activePackageId"
          :class="{
            'planItem--free': item.id !== activePackageId,
            'planItem--entp': item.id === activePackageId,
          }"
        />
      </div>
      <div v-else class="planItem__container row">
        <PackageCard
          v-for="(item, i) in yearlyPlans"
          :item="item"
          :type="$t('yearly')"
          :key="i"
          :active="item.id === activePackageId"
          :class="{
            'planItem--free': item.id !== activePackageId,
            'planItem--entp': item.id === activePackageId,
          }"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { encrypt } from "../../helpers/subscription.helper";
import Modules from "../../mixins/Modules";
import PackageCard from "./PackageCard.vue";
export default {
  mixins: [Modules],
  components: {
    PackageCard,
  },
  data() {
    return {
      packageList: [],
      activeComputed: "monthly",
    };
  },
  computed: {
    monthlyPlans() {
      return this.packageList.filter((pl) => pl.period <= 30);
    },
    yearlyPlans() {
      return this.packageList.filter((pl) => pl.period === 365);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    GET_FREE_PACKAGE() {
      const item = this.packageList.find((p) => p.totalCost === 0);
      this.GO_TO_SUBSCRIPTON(item);
    },
    GET_SELECTED_PACKAGE(id) {
      const item = this.packageList.find((p) => p.id === id);
      this.GO_TO_SUBSCRIPTON(item);
      this.$store.commit("SET_PACKAGE_ID_FROM_WEBSITE", "");
    },
    GO_TO_SUBSCRIPTON(item) {
      const data = {
        id: item.id,
        amount: item.totalCost,
        currency: "USD",
      };
      const hash = encrypt(JSON.stringify(data));
      this.$router.push(`/subscription?hash=${hash}&callback=/`);
    },
    async GET_PACKAGE_LIST() {
      const response = await this.$apiHeocademy.get(
        "SubscriptionManagement/GetPackagePrices?customerId=" +
          this.getSettings.remoteSubscriptionId
      );
      if (response.message === "OK") {
        this.packageList = response.data.items;
        this.$emit("response:succeed");
      }
    },
  },
  mounted() {
    this.GET_PACKAGE_LIST();
  },
};
</script>

<style></style>
