<template>
  <div class="card" :class="{ 'bg-primary text-white': type === 'primary' }">
    <h6>{{ title }}</h6>
    <h3 class="mt-3">
      <i :class="`icon-${icon}`"></i> {{ count }}
      <strong style="font-size: 15px" v-if="newCount > 0" class="text-success">
        <img src="/panel/images/increase.png" width="20" alt="" />
        {{ newCount >= 10 ? "+" : "" }} {{ newCount }}
        {{ $t("isNew") }}
      </strong>
    </h3>
    <h6 class="mt-3">
      <small :class="{ 'text-white': type === 'primary' }">
        {{ description }}
      </small>
    </h6>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default: "primary",
      type: String,
    },
    title: {
      default: "",
      type: String,
    },
    description: {
      default: "",
      type: String,
    },
    icon: {
      default: "",
      type: String,
    },
    count: {
      default: 0,
      type: Number,
    },
    newCount: {
      default: 0,
      type: Number,
    },
  },
};
</script>

<style></style>
