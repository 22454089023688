<template>
  <Modal
    v-model="editCategoryModal"
    @ok="SEND_TO_UPDATE_CATEGORY"
    :title="$t('editCategory')"
  >
    <section class="vid-title-sec">
      <div class="container">
        <div
          v-if="alertBox.status"
          class="alert"
          :class="`alert-${alertBox.variant}`"
        >
          {{ alertBox.message }}
        </div>
        <div class="alert bg-light card" v-if="category.productCount === 0">
          <h5>
            <strong>{{ $t("removeCategoryTitle") }}</strong>
          </h5>
          <p class="mt-2">{{ $t("removeCategoryDesc") }}</p>
          <div class="d-flex justify-content-end">
            <button
              :disabled="removeAction"
              class="btn btn-danger btn-pill"
              @click="SEND_REMOVE_CATEGORY"
            >
              <loading v-if="removeAction"></loading>
              <span v-else> {{ $t("removeCategory") }}</span>
            </button>
          </div>
        </div>
        <div v-else>
          <div class="bg-light card">
            <h5 class="mb-3">{{ $t("theCategoryCouldntRemove") }}</h5>
            <p>
              {{
                $t("youHavetoChangeCategoryOfProductForRemove", {
                  count: category.productCount,
                })
              }}
            </p>
          </div>
        </div>
        <hr />
        <form id="edit-category">
          <div class="vid-title">
            <h2 class="title-hd">{{ $t("categoryName") }}</h2>
            <div class="form_field">
              <input
                type="text"
                v-model="category.name"
                required
                maxlength="100"
                :placeholder="$t('addHereMax100Char')"
              />
            </div>
          </div>
        </form>
      </div></section
  ></Modal>
</template>

<script>
import Loading from "../Shared/Loading.vue";
import Modal from "../Shared/Modal/index.vue";
export default {
  components: { Modal, Loading },
  data() {
    return {
      removeAction: false,
      alertBox: { status: false, message: "", variant: "" },
      editCategoryModal: false,
      category: {},
    };
  },
  props: {
    remove: {
      default: false,
      type: Boolean,
    },
    item: {
      default: () => {},
    },
    image: {
      default: () => {
        return {
          uri: "",
          file: [],
        };
      },
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
        this.category = { ...this.item };
      },
    },
    editCategoryModal(data) {
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
  },
  methods: {
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal === "opened") this.editCategoryModal = true;
      if (this.remove) this.removeAction = true;
    },
    async SEND_REMOVE_CATEGORY() {
      this.removeAction = true;
      this.alertBox.status = false;
      const response = await this.$api.delete(`Categories/${this.category.id}`);
      if (response.message === "OK") {
        this.alertBox = {
          status: true,
          variant: "success",
          message: this.$t("removeActionIsSucceed"),
        };
        this.$store.commit(
          "SET_REMOVE_CATEGORY_WHICH_IS_REMOVED",
          this.category.id
        );
        setTimeout(() => {
          this.editCategoryModal = false;
          setTimeout(() => {
            this.$router.push("/categories");
          }, 150);
        }, 1500);
      } else {
        this.alertBox = {
          status: true,
          variant: "danger",
          message: this.$t("removeActionIsFailed"),
        };
      }
      this.removeAction = false;
    },
    async SEND_TO_UPDATE_CATEGORY(loading) {
      this.alertBox.status = false;
      if (this.category.name === "")
        return (this.alertBox = {
          status: true,
          variant: "danger",
          message: this.$t("theCategoryNameIsRequired"),
        });
      loading(true);
      const fd = new FormData();
      fd.append("Name", this.category.name);
      if (this.image.uri !== "") fd.append("Image", this.image.file);
      this.alertBox = await this.$store.dispatch("SEND_TO_UPDATE_CATEGORY", {
        fd,
        category: {
          id: this.category.id,
          name: this.category.name,
          productCount: this.category.productCount,
          imagePath:
            this.image.uri !== "" ? this.image.uri : this.category.imagePath,
        },
      });
      loading(false);
      setTimeout(() => {
        if (this.alertBox.variant === "success") {
          this.$store.commit("SET_CATEGORY_ARE_LOADING", true);
          this.editCategoryModal = false;
        }
        setTimeout(() => {
          this.$store.commit("SET_CATEGORY_ARE_LOADING", false);
        }, 1000);
      }, 2500);
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
    this.category = { ...this.item };
  },
};
</script>
