<template>
  <div
    class="card w-100 text-center d-flex justify-content-center align-items-center"
    v-if="loading"
  >
    <loading></loading>
    <p class="mt-2">
      <small>{{ title }} {{ $t("loading") }}</small>
    </p>
  </div>
  <div v-else-if="empty" class="multiple-horizontal-card">
    <div class="w-100 text-center">
      {{ title }}
      <no-found no-button />
    </div>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
import NoFound from "../NoFound.vue";
export default {
  components: { NoFound },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    empty: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "",
      type: String,
    },
  },
};
</script>

<style></style>
