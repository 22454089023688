<template>
  <div>
    <div v-if="getActivePlan.status" class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <router-link to="/user-list">
            <CountWidget
              :count="customerCount"
              icon="user"
              :title="$t('users')"
              :newCount="newRegisteredCustomers.length"
              :description="$t('usersDescription')"
            ></CountWidget>
          </router-link>
        </div>
        <div class="col-12 col-lg-4">
          <router-link to="/orders">
            <CountWidget
              icon="user"
              :count="orderCount"
              :newCount="newOrdersCount"
              :title="$t('orders')"
              :description="$t('ordersDescription2')"
              type="secondary"
            ></CountWidget>
          </router-link>
        </div>
        <div class="col-12 col-lg-4">
          <router-link to="/type/courses">
            <CountWidget
              :count="videoCount"
              :title="$t('courses')"
              :description="$t('coursesDescription')"
            ></CountWidget>
          </router-link>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-4">
          <UsersList></UsersList>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card bg-light" style="height: 650px; overflow: auto">
            <Orders
              @update:count="orderCount = $event"
              @update:newOrdersCount="newOrdersCount = $event"
              inside
              :limit="8"
            ></Orders>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div style="height: 650px; overflow: auto">
            <ClosestEvents></ClosestEvents>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-lg-4">
          <div class="card" style="height: 500px; overflow: auto">
            <h6 class="mt-2">
              {{ $t("mostProfitableCourses") }}
            </h6>
            <BestCoursePurchasedList
              @update:count="videoCount = $event"
              class="mt-4"
            ></BestCoursePurchasedList>
          </div>
        </div>
        <div class="col-12 col-lg-8">
          <div class="card" style="height: 500px; overflow: auto">
            <h6 class="mt-2">
              {{ $t("mostPopularCourses") }}
            </h6>
            <div class="mt-4">
              <BestEnrollments inside></BestEnrollments>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Setup />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Setup from "./Setup/index.vue";
import CountWidget from "./CountWidget.vue";
import UsersList from "./UsersList.vue";
import Orders from "../ReportCenter/Orders/index.vue";
import ClosestEvents from "../ClosestEvents/index.vue";
import BestCoursePurchasedList from "../ReportCenter/CourseInsights/BestCoursePurchasedList.vue";
import BestEnrollments from "../ReportCenter/CourseInsights/BestEnrollments.vue";

export default {
  components: {
    Setup,
    UsersList,
    BestCoursePurchasedList,
    Orders,
    BestEnrollments,
    ClosestEvents,
    CountWidget,
  },
  computed: {
    ...mapGetters(["getSettings"]),
    customerCount() {
      return this.$store.state.Customers.count;
    },
    newRegisteredCustomers() {
      return this.$store.state.Customers.newRegisteredCustomers;
    },
    getActivePlan() {
      return this.$store.getters["getActivePlan"];
    },
  },
  data() {
    return {
      orderCount: 0,
      videoCount: 0,
      newOrdersCount: 0,
      typesItemLength: [],
      totalLength: 0,
    };
  },
  methods: {
    RETURN_LENGTH(type) {
      const item = this.typesItemLength.find((t) => t === type);
      if (!item) this.typesItemLength.push(type);
    },
    SET_LENGTH() {
      if (this.getSettings.isBooks === 1) this.totalLength += 1;
      if (this.getSettings.isEvents === 1) this.totalLength += 1;
      if (this.getSettings.isCourses === 1) this.totalLength += 1;
      if (this.getSettings.isSeances === 1) this.totalLength += 1;
    },
  },
  mounted() {
    this.SET_LENGTH();
  },
};
</script>

<style></style>
