<template>
  <card-overlay
    :loading="isLoading"
    :empty="items.length === 0"
    :title="$t('closestEvents')"
    class="card"
  >
    <div class="w-100">
      <div class="header-with-all-item">
        <h6>{{ $t("closestEvents") }}</h6>
        <router-link to="/calendar">{{ $t("viewAll") }} </router-link>
      </div>
      <div>
        <ClosestEventItem
          @event:done="REMOVE_EVENT"
          v-for="(item, i) in items"
          :key="i"
          :item="item"
        />
      </div>
    </div>
  </card-overlay>
</template>

<script>
import ClosestEventItem from "./Item.vue";
import CardOverlay from "../Shared/Overlays/CardOverlay.vue";
import Types from "../Type/Types";
export default {
  components: {
    CardOverlay,
    ClosestEventItem,
  },
  mixins: [Types],
  data() {
    return {
      isLoading: true,
      page: 1,
      count: 0,
      items: [],
    };
  },
  computed: {
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
  },
  methods: {
    NEW_PAGE() {
      this.page += 1;
      this.GET_ITEMS(false);
    },
    FIND_REF(id) {
      const ref = this.$refs.details.find((r) => r.item.eventItemId === id);
      if (ref) ref.showDetail(id);
    },
    CHECK_QUERY() {
      if (process.server) return;
      const event = this.$route.query.event;
      if (!isNaN(Number(event))) this.FIND_REF(Number(event));
    },
    REMOVE_EVENT(id) {
      console.log(id);

      this.items = this.items.filter((s) => s.eventItemId !== id);
    },
    async GET_ITEMS(loading = true) {
      this.isLoading = loading;
      const userFilter =
        this.getActiveUser.roleId === 3
          ? `&userId=${this.getActiveUser.id}`
          : "";
      let link = `UserEvents/ExternalEvents?currency=${this.$i18n.locale}&limit=10&page=${this.page}${userFilter}`;
      const response = await this.$api.get(link);
      if (response.message === "OK") {
        this.items = [...this.items, ...response.data.items];
        this.count = response.data.count;
        setTimeout(() => {
          this.CHECK_QUERY();
        }, 250);
      } else this.items = [];
      this.isLoading = false;
    },
  },
  created() {
    this.GET_ITEMS();
  },
};
</script>

<style></style>
