<template>
  <div class="mt-4">
    <h5 class="mb-2">
      <strong class="text-primary"
        >{{ $t("greeting.title") }} {{ getActiveUser.fullname }}.</strong
      >
    </h5>
    <p class="text-muted mb-4">
      {{ $t("greeting.description") }}
    </p>
    <div>
      <p class="text-secondary mb-2">
        <img src="/images/success.svg" width="24" alt="" />
        {{ $t("greeting.panelAvaible") }}.
        <a target="_blank" :href="getSettings.panelWebsite">
          {{ getSettings.panelWebsite }}
        </a>
        : {{ $t("greeting.viewPanelOnWebsite") }}
      </p>
      <p class="text-success">
        <img src="/images/success.svg" width="24" alt="" />
        {{ $t("greeting.academyAvaible") }}
        <a target="_blank" :href="'https://' + getSettings.website">
          {{ getSettings.website }}
        </a>
        : {{ $t("greeting.viewAcademyOnWebsite") }}
      </p>
    </div>
    <hr />
    <div class="row">
      <div class="col-12 col-lg-6">
        <ul class="greeting-page-list">
          <li>
            <img
              src="/android-chrome-384x384.png"
              width="24"
              class="mr-2"
              alt=""
            />
            {{ $t("greeting.startFreeTrial", { day: 30 }) }}
            <span class="float-right" v-if="getActivePlan.status">
              <i class="text-success icon-thumbs_up"></i>
            </span>
            <div class="mt-2 text-muted">
              {{ $t("greeting.startFreeTrialDescription", { day: 30 }) }}
            </div>
          </li>
          <li>
            <img
              src="/android-chrome-384x384.png"
              width="24"
              class="mr-2"
              alt=""
            />
            {{ $t("greeting.createCategory") }}
            <span class="float-right">
              <Loading v-if="categoriesAreLoading" />
              <i
                v-else-if="getCategories.length > 0"
                class="text-success icon-thumbs_up"
              ></i>
            </span>
            <div class="mt-2 text-muted">
              {{ $t("greeting.createCategoryDescription") }}
            </div>
          </li>
          <li>
            <img
              src="/android-chrome-384x384.png"
              width="24"
              class="mr-2"
              alt=""
            />
            {{ $t("greeting.editAcademy") }}
            <span class="float-right">
              <i class="text-success icon-thumbs_up"></i>
            </span>
            <div class="mt-2 text-muted">
              {{ $t("greeting.editAcademyDescription") }}
              <a target="_blank" :href="'https://' + getSettings.website">
                <button class="btn btn-pill btn-xs btn-primary">
                  {{ $t("goToAcademy") }}
                </button>
              </a>
            </div>
          </li>
          <li>
            <img
              src="/android-chrome-384x384.png"
              width="24"
              class="mr-2"
              alt=""
            />
            {{ $t("greeting.createVideo") }}
            <span class="float-right" v-if="getCourses.length > 0">
              <i class="text-success icon-thumbs_up"></i>
            </span>
            <div class="mt-2 text-muted">
              {{ $t("greeting.createVideoDescription") }}
            </div>
          </li>
          <li>
            <img
              src="/android-chrome-384x384.png"
              width="24"
              class="mr-2"
              alt=""
            />
            {{ $t("greeting.addSectionVideoInVideo") }}
            <span class="float-right" v-if="getCourses.length > 0">
              <i class="text-success icon-thumbs_up"></i>
            </span>
            <div class="mt-2 text-muted">
              {{ $t("greeting.addSectionVideoInVideoDescription") }}
            </div>
          </li>
        </ul>
        <div class="mt-4">
          <button
            @click="$emit('next:step', 2)"
            class="btn-pill btn btn-primary btn-sm"
          >
            {{ $t("greeting.startSetup") }}
          </button>
        </div>
      </div>
      <div class="col-12 col-lg-6 text-center mt-4 mt-lg-0">
        <img src="/images/side1.webp" width="90%" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    getActivePlan() {
      return this.$store.getters["getActivePlan"];
    },
    getSettings() {
      return this.$store.getters["getSettings"];
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
    getCategories() {
      return this.$store.state.Category.categories;
    },
    getCourses() {
      return this.$store.state.Courses.courses;
    },
    categoriesAreLoading() {
      return this.$store.state.Category.categoriesAreLoading;
    },
  },
};
</script>

<style></style>
