export default {
  computed: {
    getActivePlan() {
      return this.$store.getters["getActivePlan"];
    },
    planStatus() {
      return this.$store.getters["getActivePlan"]?.status || "ERROR_PLAN";
    },
    getActivePackage() {
      return this.$store.getters["getActivePlan"]?.package || {};
    },
    getLastPlanUpdates() {
      return this.$store.getters["getActivePlan"]?.planUpdates || {};
    },
    activePackageId() {
      console.log(this.$store.getters["getActivePlan"]);
      if (!this.$store.getters["getActivePlan"].status) return -1;
      return this.$store.getters["getActivePlan"]?.packagePriceId || -1;
    },
    activePlan() {
      return this.$store.getters["getActivePlan"]?.modules || [];
    },
    // öğrenci limiti
    // [required]
    studentModule() {
      const moduleObj = this.activePlan.find(
        (plan) => plan.type === 1 && plan.isActive
      ) || { isActive: true, value: -1 };
      return moduleObj;
    },
    // dosya yükleme limiti
    // [required]
    uploadLimitModule() {
      const moduleObj = this.activePlan.find(
        (plan) => plan.type === 2 && plan.isActive
      ) || { isActive: true, value: 1 };
      return moduleObj;
    },
    // tek transfer'de atılabilen en yüksek dosya transfer limiti
    // [required]
    instructorModule() {
      const moduleObj = this.activePlan.find(
        (plan) => plan.type === 3 && plan.isActive
      ) || { isActive: true, value: 1 };
      return moduleObj;
    },
    // dosya depolama limiti.
    storageModule() {
      const moduleObj = this.activePlan.find(
        (plan) => plan.type === 4 && plan.isActive
      ) || {
          isActive: false,
        } || { isActive: true, value: 1 };
      console.log(
        "module obj storage",
        this.activePlan.filter((p) => p.type === 4),
        this.activePlan
      );
      return moduleObj;
    },
    // dosya depolama limiti.
    feeModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 5) || {
          isActive: false,
        } || { isActive: true, value: 1 };
      return moduleObj;
    },
    // dosya depolama limiti.
    domainModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 6) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    siteBuilderModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 7) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    noCodeModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 8) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    easyUsageModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 9) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    eCommerceModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 10) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    courseTrackingModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 11) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    mobileResponsiveModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 12) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    sendEmailModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 13) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    reportCenterModule() {
      //
      const moduleObj = this.activePlan.find((plan) => plan.type === 14) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    examReportModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 15) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    educationStatisticsModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 16) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    calendarModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 17) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    whatsappIntegrationModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 18) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    callCenterModule() {
      //
      const moduleObj = this.activePlan.find((plan) => plan.type === 19) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    callCenterWithMobileModule() {
      //
      const moduleObj = this.activePlan.find((plan) => plan.type === 20) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    createFreeProductModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 21) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    sellReportsModule() {
      //
      const moduleObj = this.activePlan.find((plan) => plan.type === 22) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    blogModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 23) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    digitalProductModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 24) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    webinarModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 25) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    advertisementModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 26) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
    // dosya depolama limiti.
    createCouponModule() {
      const moduleObj = this.activePlan.find((plan) => plan.type === 27) || {
          isActive: false,
        } || { isActive: false };
      return moduleObj.isActive;
    },
  },
  methods: {
    returnModule(name) {
      if (name === null) return true;
      return this[name];
    },
  },
};
