import i18n from "../../plugins/i18n";

export default {
  data() {
    return {
      Types: [
        {
          type: 1,
          typeTable: 3,
          title: i18n.t("events"),
          domain: "/type/events",
          icon: "/images/event.png",
          id: "events",
          name: "event",
          newProductComponent: "NewEvent",
          dispatchPath: "GET_EVENTS",
          statePath: "events",
          stateListLoadingPath: "eventsAreLoading",
          storePath: "Events",
          tab: "eventTabs",
          editDetail: "NewEvent",
          commitRemovePath: "SET_REMOVE_EVENT",
          dispatchUpdatePath: "SEND_UPDATE_EVENT",
          computed: "getEvents",
          newProductPath: "/new-product?type=events",
          addUserToProductByManual: "SEND_USER_TO_EVENT",
          packageModule: "webinarModule",
        },
        {
          type: 2,
          typeTable: 1,
          title: i18n.t("courses"),
          domain: "/type/courses",
          icon: "/images/video.png",
          id: "courses",
          name: "course",
          newProductComponent: "NewCourse",
          dispatchPath: "GET_COURSES",
          statePath: "courses",
          stateListLoadingPath: "coursesAreLoading",
          storePath: "Courses",
          tab: "courseTabs",
          editDetail: "NewCourse",
          commitRemovePath: "SET_REMOVE_COURSE",
          dispatchUpdatePath: "SEND_UPDATE_COURSE",
          computed: "getCourses",
          newProductPath: "/new-product?type=courses",
          addUserToProductByManual: "SEND_USER_TO_COURSE",
          packageModule: null,
        },
        {
          type: 3,
          typeTable: 4,
          title: i18n.t("seances"),
          domain: "/type/seances",
          icon: "/images/seances.png",
          id: "seances",
          name: "seance",
          newProductComponent: "NewSeance",
          dispatchPath: "GET_SEANCES",
          statePath: "seances",
          stateListLoadingPath: "seancesAreLoading",
          storePath: "Seances",
          tab: "seanceTabs",
          editDetail: "NewSeance",
          commitRemovePath: "SET_REMOVE_SEANCE",
          dispatchUpdatePath: "SEND_UPDATE_SEANCE",
          computed: "getSeances",
          newProductPath: "/new-product?type=seances",
          addUserToProductByManual: "SEND_USER_TO_SEANCE",
          packageModule: null,
        },
        {
          type: 4,
          typeTable: 2,
          title: this.$custom$t("books"),
          domain: "/type/e-book",
          id: "e-book",
          name: "book",
          icon: "/images/document.png",
          newProductComponent: "NewEBook",
          dispatchPath: "GET_BOOKS",
          statePath: "books",
          stateListLoadingPath: "booksAreLoading",
          storePath: "Books",
          tab: "bookTabs",
          editDetail: "NewBook",
          commitRemovePath: "SET_REMOVE_BOOK",
          dispatchUpdatePath: "SEND_UPDATE_BOOK",
          computed: "getBooks",
          newProductPath: "/new-product?type=e-book",
          addUserToProductByManual: "SEND_USER_TO_BOOK",
          packageModule: "digitalProductModule",
        },
        {
          type: 5,
          typeTable: 5,
          title: i18n.t("blog"),
          domain: "/type/e-blog",
          id: "e-blog",
          name: "blog",
          icon: "/images/document.png",
          newProductComponent: "NewBlog",
          dispatchPath: "GET_BLOGS",
          statePath: "blogs",
          stateListLoadingPath: "blogsAreLoading",
          storePath: "Blogs",
          tab: "blogTabs",
          editDetail: "NewBlog",
          commitRemovePath: "SET_REMOVE_BLOG",
          dispatchUpdatePath: "SEND_UPDATE_BLOG",
          computed: "getBlogs",
          newProductPath: "/new-product?type=e-blog",
          addUserToProductByManual: "SEND_USER_TO_BLOG",
          packageModule: "blogModule",
        },
      ],
    };
  },
  methods: {
    FIND_TYPE(type) {
      return this.Types.find((t) => t.type === type);
    },
    FIND_TYPE_ALT(type) {
      return this.Types.find((t) => t.typeTable === type);
    },
  },
  created() {
    this.Types = this.Types.filter((sub) => {
      if (this.$store.state.User.settings.isCourses === 1 && sub.type === 2)
        return sub;
      else if (
        this.$store.state.User.settings.isSeances === 1 &&
        sub.type === 3
      )
        return sub;
      else if (this.$store.state.User.settings.isBooks === 1 && sub.type === 4)
        return sub;
      else if (this.$store.state.User.settings.isBlog === 1 && sub.type === 5)
        return sub;
      else if (this.$store.state.User.settings.isEvents === 1 && sub.type === 1)
        return sub;
      return false;
    });
    console.log("this.types", this.Types);
  },
};
