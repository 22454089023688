<template>
  <div class="mt-4">
    <div v-if="categoriesAreLoading" class="text-center my-2">
      <PageLoading />
    </div>
    <div v-else>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-7">
          <h4>
            <strong> {{ $t("categories") }}</strong>
            <strong class="mx-2 text-muted">{{ getCategories.length }}</strong>
          </h4>
          <p class="text-muted mb-4">
            {{ $t("categoryDescriptionText") }}
          </p>
        </div>
        <div
          v-if="getCategories.length > 0"
          class="p-2 text-secondary d-flex align-items-center"
        >
          <img src="/images/success.svg" width="30" alt="" />
          <div class="mt-2 mx-2">
            {{ $t("awesome") }}
            {{ $t("addedCategorySetup", { length: getCategories.length }) }}
          </div>
        </div>
      </div>
      <div v-if="getCategories.length === 0" class="text-center mt-4">
        <img src="/images/no-found-category.svg" width="160" alt="" />
        <div class="mt-4 text-muted">
          <small>{{ $t("noCategoryFound") }}</small>
          <div class="text-primary">
            <small> {{ $t("createCategoryNow") }} </small>
          </div>
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-for="(category, i) in getCategories"
        :key="i"
      >
        <div
          class="col-12 col-lg-7 my-2 card d-flex justify-content-between flex-row"
        >
          <div>
            <h5>
              <img src="/favicon-32x32.png" width="24" alt="" />
              <strong class="mx-2">{{ category.name }}</strong>
            </h5>
            <p class="text-muted">
              {{
                $t("thereIsCountProductInCategory", {
                  count: category.productCount,
                })
              }}
            </p>
          </div>
          <div>
            <button
              @click="UPDATE_CATEGORY(category)"
              class="btn btn-pill btn-warning btn-xs mx-2"
            >
              {{ $t("update") }}
            </button>
            <button
              v-if="category.productCount === 0"
              class="btn btn-pill btn-primary btn-xs"
              @click="REMOVE_CATEGORY(category)"
            >
              {{ $t("remove") }}
            </button>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 col-lg-5 my-2 card text-center">
          <router-link
            :to="{ query: { ...$route.query, modal: 'add-category' } }"
          >
            <button class="btn btn-xs btn-primary btn-pill">
              + {{ $t("addNewCategory") }}
            </button>
          </router-link>
        </div>
      </div>
      <div
        class="mt-4 row justify-content-center"
        v-if="getCategories.length > 0"
      >
        <div class="col-12 col-lg-7 text-right">
          <router-link
            :to="{
              path: '/new-product',
              query: { type: 'courses', tour: 'true' },
            }"
          >
            <button class="btn btn-pill btn-secondary">
              {{ $t("continue") }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <EditCategory :remove="edit.remove" :item="edit.category" />
    <CreateCategory no-redirect></CreateCategory>
    <Modal
      sm
      v-model="removeModal"
      v-if="removeModal"
      hide-footer
      :title="$t('removeCategory')"
    >
      {{ $t("removeCategoryByName", { name: edit.category.name }) }}
      <div
        v-if="alertBox.status"
        class="alert"
        :class="`alert-${alertBox.variant}`"
      >
        {{ alertBox.message }}
      </div>
      <div class="text-right mt-4">
        <button @click="removeModal = false" class="mx-2 btn btn-pill">
          {{ $t("cancel") }}
        </button>
        <button
          @click="SEND_REMOVE_CATEGORY()"
          :disabled="removeAction"
          class="btn btn-pill btn-primary"
        >
          <span v-if="!removeAction">
            {{ $t("remove") }}
          </span>
          <Loading v-else />
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import EditCategory from "../../../Category/EditCategory.vue";
import CreateCategory from "../../../Categories/CreateCategory.vue";
import Modal from "../../../Shared/Modal/index.vue";
import PageLoading from "../../../Shared/PageLoading.vue";

export default {
  components: {
    Modal,
    PageLoading,
    EditCategory,
    CreateCategory,
  },
  data() {
    return {
      removeModal: false,
      removeAction: false,
      alertBox: {
        status: false,
        message: "",
        variant: "",
      },
      edit: {
        category: null,
        show: false,
        remove: false,
      },
    };
  },
  computed: {
    getCategories() {
      return this.$store.state.Category.categories;
    },
    categoriesAreLoading() {
      return this.$store.state.Category.categoriesAreLoading;
    },
  },
  methods: {
    async SEND_REMOVE_CATEGORY() {
      this.removeAction = true;
      this.alertBox.status = false;
      const response = await this.$api.delete(
        `Categories/${this.edit.category.id}`
      );
      if (response.message === "OK") {
        this.alertBox = {
          status: true,
          variant: "success",
          message: this.$t("removeActionIsSucceed"),
        };
        this.$store.commit(
          "SET_REMOVE_CATEGORY_WHICH_IS_REMOVED",
          this.edit.category.id
        );
        setTimeout(() => {
          this.editCategoryModal = false;
          this.removeModal = false;
          setTimeout(() => {
            this.removeAction = false;
          }, 150);
        }, 1500);
      } else {
        this.alertBox = {
          status: true,
          variant: "danger",
          message: this.$t("removeActionIsFailed"),
        };
      }
      this.removeAction = false;
    },
    REMOVE_CATEGORY(item) {
      this.edit = { category: item, show: true };
      this.removeModal = true;
    },
    UPDATE_CATEGORY(item, remove = false) {
      this.edit = { category: item, show: true, remove };
      this.$router.replace({
        query: { ...this.$route.query, modal: "opened" },
      });
    },
  },
  mounted() {
    this.$store.dispatch("GET_CATEGORIES");
  },
};
</script>

<style></style>
