<template>
  <div class="col-xl-12 my-3">
    <article
      @click="showModal = true"
      class="event-list row hover align-items-end"
      :class="{ 'event-list-active': item.status === 2 }"
    >
      <div class="col-3 col-md-2 pl-0 text-center">
        <h2>{{ GET_DATE() }}</h2>
        <p>
          <strong>{{ GET_MONTH() }}</strong>
        </p>
        <p>
          <small>{{ GET_FULLYEAR() }}</small>
        </p>
      </div>
      <div class="col-9 col-md-10">
        <div class="row flex-column-reverse flex-lg-row">
          <div class="col-12 col-lg-12">
            <div>
              <small>{{ formatter(item.date) }} - {{ SET_END_DATE() }}</small>
            </div>
            <h5 class="text-primary">{{ item.title }}</h5>
            <p>
              <small
                >{{ item.category.name }} - <i class="icofont-user"></i>
                {{ item.userInfo.fullname }}
              </small>
            </p>
          </div>
        </div>
      </div>
    </article>
    <Modal :title="item.title" v-model="showModal" hide-footer>
      <ItemDetail :item="item" />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Shared/Modal/index.vue";
import { mapGetters } from "vuex";
import { Currency } from "gurhan/helpers/Currency";
import { formatter } from "gurhan/helpers/Date";
import ItemDetail from "./ItemDetail.vue";
export default {
  components: {
    Modal,
    ItemDetail,
  },
  props: {
    item: {
      default: () => {},
    },
  },
  data() {
    return {
      noImage: false,
      eventDone: false,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(["getSettings", "getActiveUser"]),
  },
  methods: {
    formatter,
    Currency(p) {
      return Currency(p, "TRY");
    },
    LOGIN_SUCCESS() {
      this.showFastLogin = false;
      this.showPayments = true;
    },
    JOIN_EVENT() {
      this.showModal = false;
      if (!this.getActiveUser.token) return (this.showFastLogin = true);
      else this.showPayments = true;
    },
    showDetail(id) {
      if (id !== this.item.eventItemId) return;
      this.showModal = true;
    },
    SET_END_DATE() {
      const date = new Date(this.item.date + "+03:00");
      const endDate = date.setMinutes(this.item.duration);
      const tomorrow = date.setHours(24);
      console.log(new Date(endDate).getTime(), new Date(tomorrow).getTime());

      if (new Date(endDate).getTime() < new Date(tomorrow).getTime()) {
        console.log("burada?");

        this.$emit("event:done", this.item.eventItemId);
      }
      return this.formatter(new Date(endDate));
    },
    GET_DATE() {
      return new Date(this.item.date + "+03:00").getDate();
    },
    GET_FULLYEAR() {
      return new Date(this.item.date + "+03:00").getFullYear();
    },
    GET_MONTH() {
      return new Date(this.item.date + "+03:00").toLocaleDateString(
        this.$i18n.locale,
        { month: "short" }
      );
    },
  },
};
</script>

<style></style>
