<template>
  <div>
    <ColumnBar
      v-if="!isLoading"
      :titles="items.map((item) => item.courseTitle)"
      :title="$t('studentCount')"
      :series="[
        {
          name: $t('studentCount'),
          data: items.map((item) => item.studentCount),
        },
      ]"
    />
  </div>
</template>

<script>
import ColumnBar from "./ColumnBar.vue";
export default {
  components: {
    ColumnBar,
  },
  props: {
    inside: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 10,
    },
  },
  data() {
    return {
      items: [],
      isLoading: true,
      count: 0,
      page: 1,
      //       "averageStudyTime": 0,
      // "averageQuizScore": 0,
      // "studentCount": 0,
      // "certificateCount": 0,
      // "averageCommentScore": 0,
      // "totalPrice": 3540
      headers: [
        {
          title: this.$t("productName"),
          value: "courseTitle",
          col: 12,
          md: 6,
          lg: 6,
          xl: 6,
        },
        {
          title: this.$t("averageStudyTime"),
          value: "averageStudyTime",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("averageQuizScore"),
          value: "averageQuizScore",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("studentCount"),
          value: "studentCount",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("certificateCount"),
          value: "certificateCount",
          col: 12,
          md: 2,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("totalPrice"),
          value: "totalPrice",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
      ],
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.count / 10);
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    courseRateClass(rate) {
      if (rate > 85) return "text-success";
      else if (rate > 70) return "text-info";
      else if (rate > 50) return "text-warning";
      else return "text-danger";
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { page } });
      this.GET_ITEMS();
    },
    async GET_ITEMS() {
      this.isLoading = true;
      const response = await this.$api.get(
        `Statistic/GetProductStatisticDetails?page=${this.page}&limit=${this.limit}&orderBy=student`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      }
      this.isLoading = false;
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(Number(q.page))) this.page = Number(q.page);
    this.GET_ITEMS();
  },
};
</script>

<style></style>
